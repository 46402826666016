<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="/app/dashboard">Home</a></li>
      <li class="breadcrumb-item">
        <a href="/app/ordem-servico">Ordens de serviços</a>
      </li>
      <li class="breadcrumb-item">Envelopamento</li>
      <!-- <button @click="goBack" class="btn btn-info">Voltar</button> -->
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Envelopamento</h1>
    <!-- END page-header -->
    <panel :noButton="true">
      <div
        slot="header"
        class="w-100 d-flex align-items-center justify-content-between"
      >
        <span>Listagem das caixas envelopamento</span>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :lineNumbers="false"
        :styleClass="'vgt-table striped'"
        :search-options="{ enabled: true, placeholder: 'Pesquisar' }"
        :pagination-options="pageOptions"
      >
        <div slot="emptystate">Sem registros encontrados</div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'nome_arquivo'">
            {{ props.row.ordem_servico.nome_arquivo.slice(0, -4) }}
          </span>
          <span v-if="props.column.field === 'total_objetos'">
            {{ props.row.total_objetos }}
          </span>
          <span v-if="props.column.field === 'caixa'">
            {{ props.row.caixa }}
          </span>
          <span v-if="props.column.field === 'concluidos'">
            {{ props.row.concluidos }}
          </span>
          <span v-if="props.column.field === 'em_producao'">
            {{ props.row.em_producao }}
          </span>
          <span v-if="props.column.field === 'status'">
            {{ props.row.status }}
          </span>
          <span v-if="props.column.field === 'acao'">
            <button
              style="width: 110px"
              class="btn btn-success me-2"
              @click="detalhesConcluidos(props.row)"
            >
              Concluídos
            </button>
            <button
              style="width: 110px"
              class="btn btn-primary me-2"
              @click="detalhesProducao(props.row)"
            >
              Em produção
            </button>
          </span>
        </template>
      </vue-good-table>
    </panel>
    <modal-impressao v-if="ordem" :loadItens="loadItens" :ordem="ordem" />
    <modal-reimpressao v-if="ordem" :loadItens="loadItens" :ordem="ordem" />
  </div>
</template>

<script>
import axios from 'axios'
import ModalImpressao from './components/ModalImpressao.vue'
import ModalReimpressao from './components/ModalReimpressao.vue'

export default {
  components: {
    ModalImpressao,
    ModalReimpressao
  },
  data() {
    return {
      load: false,
      ordem: undefined,
      columns: [
        {
          label: 'Nome',
          field: 'nome_arquivo',
          sortable: false
        },
        {
          label: 'Caixa',
          field: 'caixa',
          sortable: false
        },
        {
          label: 'Qtd. Objetos',
          field: 'total_objetos',
          sortable: false
        },
        {
          label: 'Concluídos',
          field: 'concluidos',
          sortable: false
        },
        {
          label: 'Em Produção',
          field: 'em_producao',
          sortable: false
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false
        },
        {
          label: 'Ações',
          field: 'acao',
          tdClass: 'text-center',
          sortable: false
        }
      ],
      rows: []
    }
  },
  methods: {
    goBack() {
      window.history.back()
    },
    detalhesConcluidos(ordem) {
      this.$router.push({
        name: 'envelopamento.concluido',
        params: {
          id: ordem.ordem_servico_id,
          caixa: ordem.caixa
        }
      })
    },
    detalhesProducao(ordem) {
      this.$router.push({
        name: 'envelopamento.producao',
        params: {
          id: ordem.ordem_servico_id,
          caixa: ordem.caixa
        }
      })
    },
    loadItens() {
      this.load = true
      this.getDados()
      setInterval(() => {
        if (this.$route.name !== 'ordem.envelopamento') return
        this.getDados()
      }, 10000)
    },
    getDados() {
      axios
        .get(
          `producao/rastreabilidade?per_page=1000&page=1&ordem_servico=` +
            this.$route.params.id
        )
        .then(res => {
          return res.data.dados
        })
        .then(res => {
          this.rows = res.data
          this.load = false
        })
    }
  },
  computed: {
    pageOptions() {
      return {
        enabled: true,
        mode: 'records',
        perPage: 20,
        position: 'bottom',
        perPageDropdown: [20, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        jumpFirstOrLast: true,
        firstLabel: 'Primeira Página',
        lastLabel: 'Última Página',
        nextLabel: 'Próxima',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Linhas por página',
        ofLabel: 'de',
        pageLabel: 'página', // for 'pages' mode
        allLabel: 'Todos',
        infoFn: params => `Página atual ${params.firstRecordOnPage}`
      }
    }
  },
  mounted() {
    this.loadItens()
  }
}
</script>
